<template>
  <div class="main-preview-container">
    <el-row
      v-for="(field, index) in fieldsData"
      :key="index"
      :xl="6"
      :lg="6"
      :md="6"
      :sm="24"
      :xs="24"
      :gutter="10"
    >
      <el-col
        :span="6"
        style="font-weight: 500; margin-bottom: 15px;"
        v-if="!checkhideFields(field)"
      >
        <div
          v-if="!field.properties.hideLabel &&  
        !checkhideLabel(field) && 
        field.input_type !== 'HEADING' && field.input_type !== 'DATA_TABLE' && field.input_type !== 'ENTITY_TABLE'"
          :style="isEdit ? 'margin-top: 5px;' : ''"
          class="d-flex"
        >
          {{ field.label }}
          <p style="color: red; margin-left: 5px" v-if="checkFieldRequired(field)">*</p>
        </div>
        <div
          v-else-if="field.properties.hideLabel "
          :style="isEdit ? 'margin-top: 5px;' : ''"
          class="d-flex"
        >
          <span>-</span>
        </div>
        <!-- <div v-else :style="isEdit ? 'margin-top: 5px;' : ''" class="d-flex">-</div>          -->
        <div v-else-if="field.input_type === 'HEADING'">
          <HeadingExecute
            :data="field"
            v-if="field.input_type === 'HEADING'"
            :form="form"
            :is-view="!isEdit"
            :hasLabel="false"
            :profilePage="true"
          ></HeadingExecute>
        </div>
      </el-col>
      <el-col :xl="18" :lg="18" :md="18" :sm="24" :xs="24" style="font-weight: 400">
        <SingleLineTextExecute
          v-if="
            ((field.input_type === 'SINGLE_LINE_TEXT' ||
              field.input_type === 'EMAIL' ||
              field.input_type === 'PASSWORD' ||
              field.input_type === 'MASKED' ||
              field.input_type === 'HYPER_LINK' ) && !checkhideFields(field))  && !checkhideFields(field)
          
          "
          :data="field"
          :form="form"
          :is-view="checkIsEmailField(field)"
          :hasLabel="hasLabel"
          :profilePage="true"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></SingleLineTextExecute>
        <SelectExecute
          v-if="field.input_type === 'SELECT' && !checkhideFields(field)"
          :data="field"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="hasLabel"
          :profilePage="true"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></SelectExecute>
        <HorizontalLineExecute
          v-if="field.input_type === 'HORIZONTAL_LINE'"
          :data="field"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
        ></HorizontalLineExecute>
        <DateExecute
          v-if="field.input_type === 'DATE' && !checkhideFields(field)"
          :data="field"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></DateExecute>
        <DateTimeExecute
          :data="field"
          v-if="field.input_type === 'DATE_TIME' && !checkhideFields(field)"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></DateTimeExecute>
        <DateTimeRangeExecute
          :data="field"
          v-if="
            field.input_type === 'DATE_TIME_RANGE' && !checkhideFields(field)
          "
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></DateTimeRangeExecute>
        <DateRangeExecute
          :data="field"
          v-if="field.input_type === 'DATE_RANGE' && !checkhideFields(field)"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></DateRangeExecute>
        <CheckBoxGroupExecute
          :data="field"
          v-if="
            field.input_type === 'CHECKBOX_GROUP' && !checkhideFields(field)
          "
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></CheckBoxGroupExecute>
        <RadioGroupExecute
          :data="field"
          v-if="
            field.input_type === 'RADIO_BUTTON_GROUP' && !checkhideFields(field)
          "
          :form="form"
          :hasLabel="hasLabel"
          :is-view="!isEdit"
          :fieldsData="fieldsData"
          :profilePage="true"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></RadioGroupExecute>
        <TimeExecute
          :data="field"
          v-if="field.input_type === 'TIME' && !checkhideFields(field)"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></TimeExecute>
        <TimeRangeExecute
          :data="field"
          v-if="field.input_type === 'TIME_RANGE' && !checkhideFields(field)"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></TimeRangeExecute>
        <FileExecute
          :data="field"
          v-if="field.input_type === 'FILE'"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
        ></FileExecute>
        <MultiLineTextExecute
          :data="field"
          v-if="
            field.input_type === 'MULTI_LINE_TEXT' ||
            (field.input_type === 'RICH_TEXT' && !checkhideFields(field))
          "
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></MultiLineTextExecute>
        <MultiSelectExecute
          :data="field"
          v-if="field.input_type === 'MULTI_SELECT' && !checkhideFields(field)"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></MultiSelectExecute>
        <YesOrNoExecute
          :data="field"
          v-if="field.input_type === 'YES_OR_NO' && !checkhideFields(field)"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></YesOrNoExecute>
        <NumberExecute
          :data="field"
          v-if="
          ((field.input_type === 'NUMBER' ||
              field.input_type === 'ZIP_CODE' ) && !checkhideFields(field))  && !checkhideFields(field)"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></NumberExecute>

        <PhoneCountryCodeExecute
          :data="field"
          v-if="field.input_type === 'PHONE_COUNTRY_CODE'&& !checkhideFields(field)"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          @applyFormRules="rulesEventEmitter"
        ></PhoneCountryCodeExecute>
        <CurrencyExecute
          :data="field"
          v-if="field.input_type === 'CURRENCY' && !checkhideFields(field)"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></CurrencyExecute>
        <CheckBoxExecute
          :data="field"
          v-if="field.input_type === 'CHECKBOX' && !checkhideFields(field)"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :fieldsData="fieldsData"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></CheckBoxExecute>
        <RadioExecute
          :data="field"
          v-if="field.input_type === 'RADIO' && !checkhideFields(field)"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></RadioExecute>
        <ESignatureExecute
          :data="field"
          v-if="field.input_type === 'SIGNATURE'"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
        ></ESignatureExecute>
        <GlobalVariableExecute
          :data="field"
          v-if="
            field.input_type === 'GLOBAL_VARIABLE' && !checkhideFields(field)
          "
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></GlobalVariableExecute>

        <EntityVariableExecute
          :data="field"
          v-if="
            field.input_type === 'ENTITY_VARIABLE' && !checkhideFields(field)
          "
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :fieldsData="fieldsData"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
          @entityDataUpdated="setDataToEntityVariables"
          @clearEntityFields="unsetEntityVariables"
        ></EntityVariableExecute>

        <EntityExecute
          :data="field"
          v-if="field.input_type === 'ENTITY' && !checkhideFields(field)"
          :form="form"
          :is-view="!isEdit"
          :isView="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :fieldsData="fieldsData"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
          @entityDataUpdated="setDataToEntityVariables"
          @clearEntityFields="unsetEntityVariables"
        ></EntityExecute>

        <ParagraphExecute
          :data="field"
          v-if="field.input_type === 'PARAGRAPH'"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
        ></ParagraphExecute>
        <SingleLineContentExecute
          :data="field"
          v-if="field.input_type === 'SINGLE_LINE_CONTENT'"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="hasLabel"
          :profilePage="true"
        ></SingleLineContentExecute>
        <AuthorizedSignatureExecute
          :data="field"
          v-if="field.input_type === 'AUTHORIZED_SIGNATURE'"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
        ></AuthorizedSignatureExecute>
        <!-- IMage html formula -->
        <FormulaExecute
          :data="field"
          v-if="field.input_type === 'FORMULA' && !checkhideFields(field)"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></FormulaExecute>
        <ActionButtonExecute
          :data="field"
          v-if="field.input_type === 'ACTION_BUTTON' && !checkhideFields(field)"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="false"
          :profilePage="true"
          :fieldsData="fieldsData"
          :checkIsDisabled="checkIsDisabled(field)"
          :entityDataId="entityDataId"
          :entityId="entityId && entityId._id ? entityId._id : entityId"
          @applyFormRules="rulesEventEmitter"
        ></ActionButtonExecute>

        <ImageExecute
          :data="field"
          v-if="field.input_type === 'IMAGE' ||
          field.input_type === 'GIF'"
          :form="form"
          :is-view="!isEdit"
          :profilePage="true"
          :hasLabel="hasLabel"
        ></ImageExecute>
        <VideoExecute
          :data="field"
          v-if="field.input_type === 'VIDEO'"
          :form="form"
          :is-view="!isEdit"
          :profilePage="true"
          :hasLabel="hasLabel"
        ></VideoExecute>
        <HtmlExecute
          :data="field"
          v-if="field.input_type === 'HTML_CONTENT'"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="hasLabel"
          :profilePage="true"
        ></HtmlExecute>
        <ListExecute
          :data="field"
          v-if="field.input_type === 'LIST' && !checkhideFields(field)"
          :form="form"
          :is-view="!isEdit"
          :profilePage="true"
          :hasLabel="hasLabel"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></ListExecute>
        <DIVExecute
          :data="field"
          v-if="field.input_type === 'DIVISION'"
          :form="form"
          :profilePage="true"
        ></DIVExecute>
        <CurrencyTypesExecute
          :data="field"
          v-if="field.input_type === 'CURRENCY_TYPE' && !checkhideFields(field)"
          :form="form"
          :is-view="!isEdit"
          :profilePage="true"
          :hasLabel="hasLabel"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></CurrencyTypesExecute>
        <WeekDaysExecute
          :data="field"
          v-if="field.input_type == 'WEEKDAYS' && !checkhideFields(field)"
          :form="form"
          :is-view="!isEdit"
          :profilePage="true"
          :hasLabel="hasLabel"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></WeekDaysExecute>
        <StarRatingExecute
          :data="field"
          v-if="field.input_type == 'STAR_RATING' && !checkhideFields(field)"
          :form="form"
          :is-view="!isEdit"
          :profilePage="true"
          :hasLabel="hasLabel"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></StarRatingExecute>

        <PaymentExecute
          :data="field"
          v-if="field.input_type === 'PAY_BUTTON'"
          :form="form"
          :is-view="!isEdit"
          :hasLabel="hasLabel"
          :profilePage="true"
          :entityDataId="entityDataId"
          :currentEntity="entityId"
          :currentStep="templateId"
          :fieldsData="fieldsData"
          type="APP_USER"
        ></PaymentExecute>
        <AggregateFunctionExecute
          :data="field"
          v-if="
            field.input_type === 'AGGREGATE_FUNCTION' && !checkhideFields(field)
          "
          :form="form"
          :fieldsData="fieldsData"
          :is-view="!isEdit"
          :hasLabel="hasLabel"
          :profilePage="true"
          @applyFormRules="rulesEventEmitter"
          :checkIsDisabled="checkIsDisabled(field)"
        ></AggregateFunctionExecute>
        <ConcatenateExecute
          :data="field"
          v-if="field.input_type === 'CONCATENATE' && !checkhideFields(field) "
          :form="form"
          :is-view="!isEdit"
          :hasLabel="hasLabel"
          :colorFields="colorFields"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></ConcatenateExecute>
        <RandomTextExecute
          :data="field"
          v-if="field.input_type === 'RANDOM_TEXT' && !checkhideFields(field) "
          :form="form"
          :is-view="!isEdit"
          :hasLabel="hasLabel"
          :colorFields="colorFields"
          :checkIsDisabled="checkIsDisabled(field)"
          @applyFormRules="rulesEventEmitter"
        ></RandomTextExecute>
        <AutoIncrementExecute
          :data="field"
          v-if="
            field.input_type === 'AUTO_INCREMENT_NUMBER' &&
            !checkhideFields(field)
          "
          :form="form"
          :is-view="!isEdit"
          :entityDataId="entityDataId"
          :entityId="entityId && entityId._id ? entityId._id : entityId"
          @applyFormRules="rulesEventEmitter"
          :hasLabel="false"
        ></AutoIncrementExecute>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";

export default {
  mixins: [TemplateBuilderHelper],
  props: [
    "fieldsData",
    "form",
    "isEdit",
    "hasLabel",
    "profilePage",
    "hasRepeatableTemplate",
    "isStandard",
    "templateRules",
    "entityDataId",
    "entityId",
    "templateId"
  ],
  components: {
    SingleLineTextExecute: () =>
      import("../templates/formComponentsExecute/SingleLineTextExecute.vue"),
    SelectExecute: () =>
      import("../templates/formComponentsExecute/SelectExecute.vue"),
    HorizontalLineExecute: () =>
      import("../templates/formComponentsExecute/HorizontalLineExecute"),
    DateExecute: () => import("../templates/formComponentsExecute/DateExecute"),
    DateTimeExecute: () =>
      import("../templates/formComponentsExecute/DateTimeExecute"),
    DateTimeRangeExecute: () =>
      import("../templates/formComponentsExecute/DateTimeRangeExecute"),
    DateRangeExecute: () =>
      import("../templates/formComponentsExecute/DateRangeExecute"),
    TimeExecute: () => import("../templates/formComponentsExecute/TimeExecute"),
    TimeRangeExecute: () =>
      import("../templates/formComponentsExecute/TimeRangeExecute"),
    FileExecute: () => import("../templates/formComponentsExecute/FileExecute"),
    MultiLineTextExecute: () =>
      import("../templates/formComponentsExecute/MultiLineTextExecute"),
    RadioGroupExecute: () =>
      import("../templates/formComponentsExecute/RadioButtonGroupExecute.vue"),
    MultiSelectExecute: () =>
      import("../templates/formComponentsExecute/MultiSelectExecute"),
    NumberExecute: () =>
      import("../templates/formComponentsExecute/NumberExecute"),
    YesOrNoExecute: () =>
      import("../templates/formComponentsExecute/YesOrNoExecute"),
    HeadingExecute: () =>
      import("../templates/formComponentsExecute/HeadingExecute"),
    CheckBoxExecute: () =>
      import("../templates/formComponentsExecute/CheckBoxExecute"),
    RadioExecute: () =>
      import("../templates/formComponentsExecute/RadioExecute"),
    ESignatureExecute: () =>
      import("../templates/formComponentsExecute/ESignatureExecute"),
    ParagraphExecute: () =>
      import("../templates/formComponentsExecute/ParagraphExecute"),
    GlobalVariableExecute: () =>
      import("../templates/formComponentsExecute/GlobalVariableExecute"),
    EntityVariableExecute: () =>
      import("../templates/formComponentsExecute/EntityVariableExecute"),
    EntityExecute: () =>
      import("../templates/formComponentsExecute/EntityExecute"),
    SingleLineContentExecute: () =>
      import("../templates/formComponentsExecute/SingleLineContentExecute"),
    AuthorizedSignatureExecute: () =>
      import("../templates/formComponentsExecute/AuthorizedSignatureExecute"),
    ImageExecute: () =>
      import("../templates/formComponentsExecute/ImageExecute"),
    VideoExecute: () =>
      import("../templates/formComponentsExecute/VideoExecute"),
    FormulaExecute: () =>
      import("../templates/formComponentsExecute/FormulaExecute"),
    ActionButtonExecute: () =>
      import("../templates/formComponentsExecute/ActionButtonExecute"),
    HtmlExecute: () => import("../templates/formComponentsExecute/HtmlExecute"),
    ListExecute: () => import("../templates/formComponentsExecute/ListExecute"),
    DIVExecute: () =>
      import("../templates/formComponentsExecute/DIVExecute.vue"),
    CurrencyTypesExecute: () =>
      import("../templates/formComponentsExecute/CurrencyTypesExecute.vue"),
    PhoneCountryCodeExecute: () =>
      import("../templates/formComponentsExecute/PhoneCountryCodeExecute.vue"),
    CurrencyExecute: () =>
      import("../templates/formComponentsExecute/CurrencyExecute.vue"),
    WeekDaysExecute: () =>
      import("../templates/formComponentsExecute/WeekDaysExecute.vue"),
    StarRatingExecute: () =>
      import("../templates/formComponentsExecute/StarRatingExecute.vue"),
    CheckBoxGroupExecute: () =>
      import("../templates/formComponentsExecute/CheckBoxGroupExecute.vue"),
    PaymentExecute: () =>
      import("../templates/formComponentsExecute/PaymentExecute.vue"),
    AggregateFunctionExecute: () =>
      import("../templates/formComponentsExecute/AggregateFunctionExecute.vue"),
    ConcatenateExecute: () =>
      import("../templates/formComponentsExecute/ConcatenateExecute.vue"),
    RandomTextExecute: () =>
      import("../templates/formComponentsExecute/RandomTextExecute.vue"),
    AutoIncrementExecute: () =>
      import("../templates/formComponentsExecute/AutoIncrementExecute.vue")
  },
  data() {
    return {
      hideFields: [],
      disabledFields: [],
      colorFields: {}
    };
  },
  mounted() {
    this.rulesEventEmitter();
  },
  methods: {
    checkIsDisabled(field) {
      return this.disabledFields.find(e => e == field.key) ? true : false;
    },
    checkhideFields(field) {
      return this.hideFields.find(e => e == field.key) ? true : false;
    },
    checkhideLabel(field) {
      if (
        this.checkhideFields(field) ||
        field?.properties?.filed_content == "Hide"
      ) {
        field.hideLabel == true;
        return true;
      }
    },
    rulesEventEmitter() {
      this.applyRulesOnFields(this.fieldsData, this.templateRules, this.form);
    },
    async setDataToEntityVariables(data, parent, label, changed) {
      let entityId = data?._id ? data._id : null;
      if (label) {
        this.$set(this.form, parent.key + "/name", label);
      }
      this.fieldsData.forEach(field => {
        if (
          field.relationship_key == parent.key &&
          (field.field_assignable == "read_only" ||
            field.inputType == "ENTITY" ||
            !this.form[field.key] ||
            changed)
        ) {
          if (
            field.global_variable_entity_field.includes("~") ||
            field.variable_action === "RESOURCE_AVERAGE"
          ) {
            // we need to skip assign data part we if the field is relationship field
            // becase we are setting entity data. but entity data don't have relation data by default. it makes value as null.
            return;
          }
          if (field.global_variable_entity_field) {
            let templateId = "";
            let key = "";
            let value = "";
            let valueName = "";
            if (field.global_variable_entity_field.includes("#")) {
              templateId = field.global_variable_entity_field.split("#")[0];
              key = field.global_variable_entity_field.split("#")[1];
            } else {
              templateId = field.global_variable_entity_field;
            }
            if (parent?.allow_multiple) {
              if (data.length) {
                let dataValues = [],
                  result = 0,
                  min;
                let existedIds = (this.form &&
                this.form[field.key] &&
                this.form[field.key].length
                  ? this.form[field.key]
                  : []
                )
                  .filter(e => e.parentDataId)
                  .flatMap(dt => dt.parentDataId);
                (data || []).forEach(e => {
                  if (
                    e?.entityData?.[templateId]?.[key] &&
                    existedIds.indexOf(e._id) == -1
                  ) {
                    if (field.inputType == "DATA_TABLE") {
                      if (e.entityData[templateId][key].length) {
                        e?.entityData[templateId][key].forEach(dt => {
                          dataValues.push({
                            ...dt,
                            ...{
                              parentDataId: e._id,
                              parentDataName: e.parentDataName
                            }
                          });
                        });
                      }
                    } else if (this.isNumber(e.entityData[templateId][key])) {
                      let v = e.entityData[templateId][key];
                      if (
                        field.variable_action == "SUM" ||
                        field.variable_action == "AVERAGE"
                      ) {
                        result = result + parseInt(v);
                      } else if (
                        field.variable_action == "HIGHEST" &&
                        v > result
                      ) {
                        result = parseInt(v);
                      } else if (field.variable_action == "LOWEST") {
                        if (v < min || min == undefined) {
                          min = parseInt(v);
                        }
                      }
                    }
                  }
                });
                if (existedIds.length) {
                  dataValues = [...dataValues, ...(this.form[field.key] || [])];
                }
                if (field.inputType == "DATA_TABLE") {
                  this.$set(this.form, field.key, dataValues);
                } else {
                  if (field.variable_action == "AVERAGE") {
                    let avg = result / data.length;
                    this.$set(this.form, field.key, avg);
                  } else if (field.variable_action == "LOWEST") {
                    this.$set(this.form, field.key, min);
                  } else {
                    this.$set(this.form, field.key, result);
                  }
                }
              }
            } else {
              if (
                data &&
                data.entityData &&
                data.entityData[templateId] &&
                data.entityData[templateId][key]
              ) {
                value = data.entityData[templateId][key];

                if (field.inputType == "ENTITY") {
                  valueName = data.entityData[templateId][key + "/name"];
                  this.$set(this.form, field.key + "/default", [value]);
                }
              }
              if (this.form[field.key]) {
                if (data.entityData[templateId][key + "_code"]) {
                  this.form[field.key + "_code"] =
                    data.entityData[templateId][key + "_code"];
                }
                this.form[field.key] = value;
                if (valueName) {
                  this.form[field.key + "/name"] = valueName;
                }
              } else {
                this.$set(this.form, field.key, value);
                if (valueName) {
                  this.$set(this.form, field.key + "/name", valueName);
                }
              }
            }
          }
        }
      });
      if (!this.form.parent_data) {
        this.form.parent_data = {};
      }
      if (entityId) {
        this.form.parent_data[entityId] = data;
      } else {
        this.form.parent_data = {};
      }
      // this.rulesEventEmitter();
    },
    isNumber(value) {
      return typeof value === "number" || !isNaN(parseFloat(value));
    },
    async unsetEntityVariables(parent) {
      this.getFieldsData.forEach(field => {
        if (field.relationship_key == parent.key) {
          if (field?.inputType == "DATA_TABLE") {
            this.$set(this.form, field.key, []);
          } else {
            this.$set(this.form, field.key, "");
          }
        }
      });
      // if (!this.form.parent_data) {
      //   this.form.parent_data = {};
      // }

      // if (entityId) {
      //   this.form.parent_data[entityId] = [];
      // }
      //  this.rulesEventEmitter();
    },
    checkIsEmailField(field) {
      if (this.isStandard && field?.key == "email") {
        return true;
      }
      return !this.isEdit;
    },
    checkFieldRequired(data) {
      if (data) {
        if (data.allow_multiple && data.inputType == "WEEKDAYS") {
          if (data.min_value > 0) {
            return true;
          }
          return false;
        } else {
          return data?.validations?.required;
        }
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.main-preview-container {
}
</style>
